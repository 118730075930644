<template>
  <app-layout>
    <v-container grid-list-md>
      <v-card fill-height style="min-height: 800px">
        <v-tabs fill-height slider-color="accent">
          <template v-for="tab in tabs">
            <v-tab
              :key="'tab-' + tab.tabLabel"
              v-if="allow(tab.perm)"
              :to="{ name: tab.routeTo.name }"
              ripple
              >{{ tab.tabLabel }}
            </v-tab>
          </template>
        </v-tabs>
        <router-view />
      </v-card>
    </v-container>
  </app-layout>
</template>
<script>
import AppLayout from '@/components/AppLayout'

export default {
  name: 'Service',
  components: {
    AppLayout,
  },
  data: function () {
    return {
      tabs: [
        { tabLabel: 'Importe', routeTo: { name: 'Service.Imports' } },
        {
          tabLabel: 'Zahlungsjahre',
          routeTo: { name: 'Service.PaymentYearSettings' },
        },
        {
          tabLabel: 'Stand der Bearbeitung',
          routeTo: { name: 'Service.StatusStats' },
        },
        {
          tabLabel: 'Version',
          routeTo: { name: 'Service.VersionInfo' },
        },
        {
          tabLabel: 'Hebesatzkonflikte',
          routeTo: { name: 'Service.Conflicts' },
        },
      ],
    }
  },
  methods: {
    allow(perm = []) {
      for (let i in perm) {
        if (!this.$can(perm[i])) return false
      }
      return true
    },
  },
  watch: {
    '$route.name': {
      handler(value) {
        if (value === 'Service') {
          const { name } = this.tabs.find(
            (t) => t.tabLabel === 'Importe'
          ).routeTo
          this.$router.replace({ name })
        }
      },
      immediate: true,
    },
  },
}
</script>
